<template>
  <div class="mcn-pages-contents">
    <div class="mcn-pages-video-box">
      <video src="../../assets/video/community.mp4" autoplay loop playsinline></video>
    </div>
    <div class="text"> 
      <div class="close-menu" @click="this.$parent.onMinusButtonClick" style="width:500px;margin-left:30px;">
        SUPERFAN
        <font-awesome-icon icon="fa-solid fa-circle-minus" class="minus" />
      </div>
      <div class="desc ">
        <p><strong>SUPERFAN</strong> represents the identity of the creator</p>
        <p>in themes of games, art, food and etc.</p>
        <p>Community members complete mission tasks from the creator</p>
        <p>and gets reward distributions as compensation.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
