<template>
  <div class="mobile-wrap">
    <MainView />
    <MCNView />
    <DescSliderView />
    <MCNTView />
    <PartnersView />
    <FooterView />
  </div>
</template>

<script>
import MainView from "./MainView.vue";
import MCNTView from "./MCNTView.vue";
import DescSliderView from "./DescSliderView.vue";
import PartnersView from "./PartnersView.vue";
import FooterView from "./FooterView.vue";
import MCNView from "./MCNView.vue";

export default {
  components: {
    DescSliderView,
    MCNView,
    MainView,
    PartnersView,
    MCNTView,
    FooterView,
  }
}
</script>

<style scoped>

.mobile-wrap {
  display: flex;
  flex-direction: column;
}
</style>