<template>
  <div class="mobile-pages">
    <div class="mobile-main-page">
      <div class="mobile-main-page-background">
        <video src="../../assets/video/m-main.mp4" muted="ture" autoplay="true" loop="true" playsinline="true"></video>
      </div>

      <a href="#section2" class="arrow-link">
        <div class="wave">
          <font-awesome-icon icon="fa-solid fa-arrow-down"
            class="arrow animate__animated animate__heartBeat animate__infinite" />
        </div>
      </a>

      <div class="mobile-text-area">
        <div class="transition-text-wrap">
          <span v-for="(item, index) in strings" :key="index" class="transition-char">
            {{ item }}
          </span>
        </div>
        <div class="transition-text-wrap">
          <span v-for="(item, index) in strings2" :key="index" class="transition-char2">
            {{ item }}
          </span>
        </div>
        <div class="transition-text-wrap">
          <span v-for="(item, index) in strings3" :key="index" class="transition-char3">
            {{ item }}
          </span>
        </div>
        <div class="transition-text-wrap input-wrap">
          <a href="mailto:contact@meta-creator.network">
            <span class="transition-char2 email-button">
              Contact to us
            </span>
          </a>
          <div class="email-input-wrap transition-char2">
            <div class="input-design">
              <input id="mobile-input" type="email" @keyup.enter="inputEnter" placeholder="Enter your e-mail address" />
            </div>
            <button class="email-submit-button">Subscribe</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';

export default {
  data() {
    return ({
      strings: "META",
      strings2: "CREATOR",
      strings3: "NETWORK",
    })
  },
  methods: {
    inputEnter() {
      let $input = $("#mobile-input");
      var inputValue = $input.val();

      if(inputValue == "") {
        return ;
      }
      
      $input.val("");
      const api = `/api/email/${inputValue}`;
      // https://meta-creator.network/api/email/:email:
      axios.get(api).then((res)=> {
        console.log(res);
        alert('subscribed');
      })
      .catch((err) => {
        alert(err);
      })
    }
  },
  mounted() {
    const self = this;

    const maxWidth = 320;

    // const $emailButton = $('.email-button');
    // const $inputWrap = $('.email-input-wrap');
    const $mobileTextArea = $(".mobile-text-area");


    if($mobileTextArea.width() == maxWidth) {
      let pageWidth = $('.mobile-main-page').width();
      
      $mobileTextArea.css({
        left: (pageWidth/2)-(maxWidth/2),
      })
    }

    // $('.email-button').click(function () {
    //   $inputWrap.css({ 'display': 'flex', 'width': '100%' });
    //   $emailButton.hide();
    //   $("#mobile-input").focus();
    // })

    $('.email-submit-button').click(function () {
      self.inputEnter();
    });

    setTimeout(() => {
      $('.text-area').show();
    }, 1500);

    let observer = new IntersectionObserver((e) => {
      e.forEach((element, index) => {
        if (element.isIntersecting) {
          setTimeout(() => {
            element.target.classList.add('transition-shakes');
          }, 50 * index);
        } else {
          element.target.classList.remove('transition-shakes');
        }
      });
    });

    let chars = document.querySelectorAll('.transition-char');
    chars.forEach(char => observer.observe(char))

    let chars2 = document.querySelectorAll('.transition-char2');
    chars2.forEach(char => observer.observe(char))

    let chars3 = document.querySelectorAll('.transition-char3');
    chars3.forEach(char => observer.observe(char))
  }
}
</script>

<style scoped lang="scss">
.mobile-text-area .transition-text-wrap .transition-char:nth-of-type(4) {
  margin-left: -0.4rem;
}

.mobile-text-area .transition-text-wrap:nth-of-type(2) .transition-char2:nth-of-type(5) {
  margin-left: -0.4rem;
}

.mobile-text-area .transition-text-wrap:nth-of-type(2) .transition-char2:nth-of-type(6) {
  margin-left: -0.1rem;
}

.mobile-text-area .transition-text-wrap:nth-of-type(3) .transition-char3:nth-of-type(5) {
  margin-left: -0.1rem;
}

.mobile-text-area .transition-text-wrap {
  height: 4rem;
  margin-top: -10px;
  display: flex;
  color: white;
  font-family: 'articulat-cf';

  -webkit-text-stroke: 1.5px white;
  letter-spacing: 2px;
  font-size: 3.5rem;
  font-weight: 900;
}

.mobile-text-area .transition-text-wrap .arrow {
  transform: rotate(-90deg);
}

.input-wrap {
  color : #000;
  margin-right: 4vw;
  justify-content: center;
  // margin-left: 2vw;
  -webkit-text-stroke: 0px !important;
}

.email-input-wrap {
  display: none;

  font-family: 'articulat-cf';
  font-size: 1rem;
  color: #000;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;


  width: 50vw;
  height: 10vh;
}


.email-input-wrap .input-design {
  width: 100%;
  padding: 8px;
  background-color: #a91dd4;
  border-radius: 6px;
  margin-top: 1rem;
}
.email-input-wrap #mobile-input {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  padding-left: 3px;
}

#mobile-input:focus {
  outline: none !important;
  border: none;
}

#mobile-input::placeholder {
  color: #a91dd4;
  color: rgba(169, 29, 212, 0.2);
  font-size: 1rem;
  padding-left: 3px;
  font-weight: 900;
  padding-bottom: 2px;
  font-family: 'articulat-cf';
}

.email-submit-button {
  color: #a91dd4 !important;
  -webkit-text-stroke: 0px !important;
  background: #1a141c;
  width: 36%;
  font-size: 1rem;
  font-weight: 900;
  border-radius: 6px;
  padding: 4px 10px;
  padding-top: 8px;
  margin-top: 0.1rem;
 }

.mobile-text-area .email-button {
  background: #a91dd4;
  border-radius: 6px;

  font-size: 0.9rem;
  letter-spacing: 0px;

  width: 32vw;
  height: 41px;

  padding-top: 4px;

  margin-top: 1.5rem;
  // margin-left: -2vw;

  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-text-stroke: 0px white;
}


.mobile-main-page {
  width:100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-main-page-background {
  width: 70vw;
  max-width: 320px;
  height: 70%;
  border-radius: 20px;
  margin-top: -5vh;
  position: relative;
  overflow: hidden;
  transform: rotate(5deg);
}

.mobile-main-page-background video {
  border-radius: 8px;
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-width: 320px;
  left: 0;
  top: 0;
  opacity: 1;
  transform: scale(1);
}

.mobile-text-area {
  position: absolute;
  top: 30vh;
  width: 80vw;
  max-width: 320px;

  left: calc(50vw - 40vw);
  margin-left: 2vw;
  font-size: 3.5rem;
}

.wave {
  width: 100px;
  height: 100px;
  background: none;
  border-radius: 50%;

  position: absolute;
  bottom: 40px;
  left: calc(50% - 55px);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: #a91dd4; //#a91dd4
    top: -1px;
    left: -1px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: wave 3s infinite linear;
  }

  &:after {
    opacity: 0;
    animation: wave 3s 1.5s infinite linear;
  }
}

@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }

  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

.wave .arrow {
  color: #a91dd4;
  font-size: 30px;
  position: relative;
  left: 37px;
  top: 32px;
}
</style>