<template>
  <div class="main-page main" style="overflow: inherit;">
    <Hover3DEffect />
    <div class="text-area">
      <div class="transition-text-wrap">
        <span v-for="(item, index) in strings" :key="index" class="transition-char">
          {{ item }}
        </span>
      </div>
      <div class="transition-text-wrap">
        <span v-for="(item, index) in strings2" :key="index" class="transition-char2">
          {{ item }}
        </span>
        <div class="email-input-wrap">
        <a href="mailto:contact@meta-creator.network">
          <span class="transition-char2 email-button">
            Contact to us
          </span>
        </a>
          <!-- <div class="transition-char2 input-wrap">
            <input id="input" type="email" @keyup.enter="inputEnter" placeholder="Enter your e-mail address" />
            <button class="email-submit-button">Subscribe</button>
          </div> -->
        </div>
      </div>
      <div class="transition-text-wrap">
        <span v-for="(item, index) in strings3" :key="index" class="transition-char3">
          {{ item }}
        </span>
      </div>
    </div>
    <a href="#section2" class="arrow-link">
      <div class="wave">
        <font-awesome-icon icon="fa-solid fa-arrow-down" class="arrow animate__animated animate__heartBeat animate__infinite" />
      </div>
    </a>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import Hover3DEffect from "../components/Hover3DEffect.vue";

export default {
  name: 'MainView',
  components: {
    Hover3DEffect,
  },
  data() {
    return ({
      strings: "META",
      strings2: "CREATOR",
      strings3: "NETWORK",
    })
  },
  methods: {
    inputEnter() {
      var inputValue = $('#input').val();
      $("#input").val("");

      if(inputValue == "") {
        return ;
      }

      console.log('inputValue', inputValue);
      const api = `/api/email/${inputValue}`;
      // https://meta-creator.network/api/email/:email:
      axios.get(api).then((res)=> {
        console.log(res);
        alert('subscribed');
      })
      .catch((err) => {
        alert(err);
      })
    }
  },
  mounted() {
    var self = this;
    // var $emailButton = $('.email-button');
    // var $inputWrap = $('.input-wrap');
    // $('.email-button').click(function() {
    //   $inputWrap.css({ 'display': 'flex'});
    //   $emailButton.hide();
    //   $("#input").focus();
    // })

    $('.email-submit-button').click(function() { 
      self.inputEnter();
    });

    setTimeout(() => {
      $('.text-area').show();
    }, 1500);

    let observer = new IntersectionObserver((e) => {
      e.forEach((element, index) => {
        if (element.isIntersecting) {
          setTimeout(() => {
            element.target.classList.add('transition-shakes');
          }, 50 * index);
        }else{
          element.target.classList.remove('transition-shakes');
        }
      });
    });

    let chars = document.querySelectorAll('.transition-char');
    chars.forEach(char => observer.observe(char))
    
    let chars2 = document.querySelectorAll('.transition-char2');
    chars2.forEach(char => observer.observe(char))
 
    let chars3 = document.querySelectorAll('.transition-char3');
    chars3.forEach(char => observer.observe(char))
  }
}
</script>

<style scoped lang="scss">
.email-submit-button {
  color: #a91dd4 !important;
  -webkit-text-stroke: 0px !important;
  background: #1a141c;
  font-size: 24px;
  font-weight: 900;
  border-radius: 6px;
  padding: 4px 10px;
  height: 40px;
  margin-left:-10px;
}

.email-input-wrap {
  width: 500px;
  height: 60px;
  margin-left: 12px;
}
.input-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
 
  display: none;
  width: 420px;
  height: 60px;
  margin-top: 88px;
  background-color: #a91dd4;
  border-radius: 6px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.email-input-wrap .input-wrap input#input{ 
  border-radius: 6px;
  padding-left: 10px;
  padding-top: 4px;
  font-size: 24px;
  font-family: 'articulat-cf';
  color: #000 !important;
  width: 330px;
  height: 40px;
  -webkit-text-stroke: 0px !important;
}

#input:focus {
  outline: none !important;
  border: none;
}

#input::placeholder {
  color: #a91dd4;
  color: rgba(169, 29, 212, 0.2);
  font-size:20px;
  font-weight: 900;
  padding-bottom: 2px;
  font-family: 'articulat-cf';
}
.email-button  {
  width: 200px; 
  height: 60px;
  color: #fff; 
  background: #a91dd4;
  font-size: 24px;
  font-weight: 100;
  letter-spacing: 2px;
  border-radius: 6px;

  margin-top: 88px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}
.text-area {
  width: 960px !important;
  display: none;
}

// .transition-shakes {
//   animation-iteration-count: 0;
//   animation: shakes 1.5s 1 ease;
// }

// @keyframes shakes {
//   0% {
//     transform: translateX(250px);
//   }

//   30% {
//     transform: translateX(-20px);
//   }

//   60% {
//     transform: translateX(10px);
//   }

//   100% {
//     transform: translateX(0px);
//   }
// }

.transition-text-wrap {
  display: flex;
  font-size: 9rem;
}

.text-area {
  width: 51vw;
  color: white;
  z-index: 2000;
  font-size: 9rem;
  font-weight: 900;
  -webkit-text-stroke: 3px white;
  // text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.text-area div.transition-text-wrap {
  width: 100%;
  height: 108px;
  margin-bottom: 30px;
}

.text-area div.transition-text-wrap:nth-of-type(1) {
  text-align: left;
  margin-left: 100px;
}

.text-area div.transition-text-wrap:nth-of-type(2) {
  text-align: left;
}

.text-area div.transition-text-wrap:nth-of-type(3) {
  display: flex;
  justify-content: flex-end;
}

.arrow-link {
  display: block;
  position: absolute;
  bottom: 8px;
  left: 47.5vw;
  z-index: 4444;
}

.arrow {
  color: #a91dd4;
  font-size: 50px;
  position: relative;
  left: 30px;
  top: 26px;
}

.wave {
  width: 100px;
  height: 100px;
  background: none;
  // border: 1px solid ;
  border-radius: 50%;

  position: absolute;
  bottom: 50px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: #a91dd4; //#a91dd4
    top: -1px;
    left: -1px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: wave 3s infinite linear;
  }

  &:after {
    opacity: 0;
    animation: wave 3s 1.5s infinite linear;
  }
}

@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }

  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}
</style>