<template>
  <div class="mobile-pages">
    <div class="desc-slider-page">
      <div class="desc-text-wrap">
        <p class="transition-text2 transition-text desc-title main-text">
          Main
        </p>
        <p class="transition-text2 transition-text desc-title features-text">
          Features
        </p>

        <div class="gradient-background"></div>
        <div class="wave" @click="nextChange">
          <p class="animate__animated animate__wobble animate__infinite">
            <font-awesome-icon icon="fa-solid fa-arrow-down" class="arrow " />
          </p>
        </div>
      </div>
      <div class="desc-slider-wrap">
        <Splide :options="options" @splide:active="activeChange" ref="splide">
          <SplideSlide>
            <div class="slide-unit" @click="clickChange" data-slide-id="1">
              <p class="unit-title">Creator</p>
              <p class="unit-title">Membership</p>

              <div class="sub-desc-wrap" data-slide-id="1">
                <p class="unit-sub-desc" style="margin-left:3px; margin-top: 13px;">
                  Join the creator membership
                </p>
                <p class="unit-sub-desc">and stay updated on new missions</p>
                <p class="unit-sub-desc">and community event.</p>
              </div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div class="slide-unit" @click="clickChange" data-slide-id="2">
              <p class="unit-title">MCN Wallet</p>
              <p class="unit-title">System</p>

              <div class="sub-desc-wrap" data-slide-id="2" style="margin-top: -10px;">
                <p class="unit-sub-desc">CREA is an MCN wallet system for creators</p>
                <p class="unit-sub-desc">and community members to manage crypto assets.</p>
                <p class="unit-sub-desc">Enjoy easy transfer between community members.</p>
              </div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <div class="slide-unit" @click="clickChange" data-slide-id="3">
              <p class="unit-title">Mission</p>
              <p class="unit-title">& Reward</p>

              <div class="sub-desc-wrap" data-slide-id="3">
                <p class="unit-sub-desc" style="margin-left:3px; margin-top: 13px;">
                  Mission board is the playground for creators, superfans, and advertisers. 
                </p>
                <p class="unit-sub-desc">Explore and complete missions together with your favorite creator.</p>

                <!-- <p class="unit-sub-desc">for creators, communities and .</p>
                <p class="unit-sub-desc">developers Browse posted missions and get your own mission clear.</p> -->
              </div>
            </div>
          </SplideSlide>

          <SplideSlide>
            <!-- <div class="slide-unit" @click="clickChange" data-slide-id="4">
              <p class="unit-title">Heimdall</p>
              <p class="unit-title">SDK</p>

              <div class="sub-desc-wrap" data-slide-id="4">
                <p class="unit-sub-desc" style="margin-left:3px; margin-top: 13px;">Heimdall SDK tracks community
                  member's</p>
                <p class="unit-sub-desc">data on play history and mission </p>
                <p class="unit-sub-desc">progress to verify mission status.</p>
              </div>
            </div> -->
          </SplideSlide>

        </Splide>
      </div>

    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import $ from 'jquery';

export default {
  components: { Splide, SplideSlide },
  data() {
    return {
      slider: null,
      options: {
        perMove: 1,
        type: 'slide',
        perPage: 1,
        focus: 'center',
        height: '400px',
        width: '1200px',
        fixedWidth: '300px',
        gap: '10px',
        arrows: false,
        pagination: false,
      },
    }
  },
  methods: {
    nextChange() {
      let temp = this.slider.index;
      temp += 1;

      if (temp == '3') {
        this.slider.go(0);
      } else {
        this.slider.go(temp);
      }
    },
    clickChange(e) {
      let id = "";
      if (e.target.nodeName === "P") {
        id = e.target.parentNode.dataset.slideId;
      } else {
        id = e.target.dataset.slideId;
      }
      this.slider.go(id - 1);
    },
    activeChange(e) {
      $('.slide-unit').css({
        'border': '1px solid #a91dd4',
        'background': '#1a141c'
      });

      $('.slide-unit p').css({
        'color': '#a91dd4',
      });

      $('.splide__slide').eq(e.index).find('.slide-unit').css({
        'border': '1px solid #fff',
        'background': 'linear-gradient(45deg, rgba(169, 29, 212, 0.53) 13%, rgba(85, 15, 106, 0) 100%)'
      });
      $('.splide__slide').eq(e.index).find('.slide-unit p').css({
        'color': '#fff'
      });
    }
  },
  mounted() {
    this.slider = this.$refs.splide;
    this.slider.go(0);

    let observer = new IntersectionObserver((e) => {
      e.forEach(element => {
        if (element.isIntersecting) {
          element.target.style.opacity = 1;
          element.target.classList.add('animate__animated', 'animate__slideInUp');
        } else {
          element.target.style.opacity = 0;
          element.target.classList.remove('animate__animated', 'animate__slideInUp');
        }
      });
    });

    let div = document.querySelectorAll('.transition-text2');
    observer.observe(div[0]);
    observer.observe(div[1]);
  }
}
</script>

<style scoped lang="scss">
.desc-slider-page {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desc-text-wrap {
  position: absolute;

  width: 84vw;
  // max-width: 300px;
  height: 50%;
}

.desc-text-wrap .gradient-background {
  width: 100%;
  height: 60%;
  border-radius: 1rem;
  margin-left: 0rem;
  background-image: linear-gradient(20deg, rgba(171, 40, 212, 0) 58%, #550f6a 91%);

  transform: rotate(6deg);
}

.desc-text-wrap .desc-title {
  position: absolute;
  top: 2.2rem;
  left: 1.5rem;
  color: #fff;
  z-index: 4444;
  height: 4rem;
  margin-top: -10px;
  display: flex;
  font-family: 'articulat-cf';

  -webkit-text-stroke: 1px white;
  letter-spacing: 2px;
  font-size: 3.5rem;
  font-weight: 900;

}

.desc-text-wrap .features-text {
  top: 5.5rem;
  left: 3.7rem;
}

.desc-slider-wrap {
  width: 100%;

  z-index: 4444;
  position: relative;
  margin-top: 13rem;
}

.desc-slider-wrap .slide-unit {
  cursor: pointer;
  border: 1px solid #a91dd4;
  width: 74vw;
  height: 200px;
  color: #a91dd4;
  transform: rotate(6deg);
  padding-top: 14px;
  padding-left: 18px;

  position: relative;
  border-radius: 20px;
  background: #1a141c;
}

.desc-slider-wrap .sub-desc-wrap {
  position: absolute;
  top: 70px;
  left: 20px;

  font-size: 0.9rem;
}

.desc-slider-wrap .sub-desc-wrap .unit-sub-desc {
  color: #a91dd4;
  font-weight: 300;

  transform: rotate(-6deg);
}


.desc-slider-wrap .sub-desc-wrap .unit-sub-desc:first-child {
  margin-left: 3px;
  margin-top: 13px;
}


.arrow {
  position: absolute;
  font-size: 50px;
  color: #a91dd4;
  transform: rotate(270deg);
}

.wave {
  cursor: pointer;
  width: 100px;
  height: 100px;
  background: none;
  border-radius: 50%;

  position: absolute;
  left: calc(50vw - 50px);
  bottom: -180px;
  z-index: 4444;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: #a91dd4; //#a91dd4
    top: -24px;
    left: -28px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: wave 3s infinite linear;
  }

  &:after {
    opacity: 0;
    animation: wave 3s 1.5s infinite linear;
  }
}

@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }

  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}


.splide__arrows {
  display: none !important;
}

.splide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 470px !important;
}

.is-active .desc-slider .slide-unit {
  border: 1px solid whtie !important;
}


.unit-title {
  color: #a91dd4;
  font-size: 1.2rem;
  font-weight: 900;

  transform: rotate(-6deg);
}

.slide-unit p.unit-title:nth-of-type(2) {
  margin-left: 2px;
  margin-top: -6px;
}

.slide-unit p.unit-sub-desc:nth-of-type(2) {
  margin-left: 5px;
}

.slide-unit p.unit-sub-desc:nth-of-type(3) {
  margin-left: 7px;
}

.slide-unit p.unit-sub-desc:nth-of-type(4) {
  margin-left: 9px;
}
</style>