<template>
  <div class="mcn-pages-contents">
    <div class="mcn-pages-video-box">
      <video src="../../assets/video/developer.mp4" autoplay loop playsinline="true"></video>
    </div>
    <div class="text">
      <div class="close-menu" @click="this.$parent.onMinusButtonClick">
        ADVERTISER 
        <font-awesome-icon icon="fa-solid fa-circle-minus" class="minus" />
      </div>
      <div class="desc">
        <p><strong>ADVERTISER</strong> upload ad missions on the mission board to advertise</p>
        <p>their dApp and content. Setting various goals and rewards,</p>
        <p>developers acquire active users from missions.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
