<template>
  <div>
    <div class="menu-toggle">
      <a href="#" class="menu-link">
        <span class="menu-icon">
          <span class="menu-line menu-line-1"></span>
          <span class="menu-line menu-line-2"></span>
          <span class="menu-line menu-line-3"></span>
        </span>

        <span class="menu-circle">
          <span class="menu-pulse first-pulse"></span>
          <span class="menu-pulse second-pulse"></span>
        </span>
      </a>
    </div>
    <div class="menu-overlay">
      <a href="/">
        <img src="../assets/images/MCN-03.png" class="overlay-logo" />
      </a>

      <div class="overlay-content">
        <div style="height: 130px"></div>
        <div class="menu-area">
          <div class="main-menu">
            <span>WHITE PAPER</span>
          </div>
          <div class="main-menu">
            <span>TWITTER</span>
          </div>
          <div class="main-menu">
            <span>MEDIUM</span>
          </div>
          <div class="main-menu toggle-email">
            <p>
              contact@meta-creator.network
            </p>         
            </div>
        </div>
      </div>

      <div class="overlay-footer main-menu">
        <img src="../assets/images/black-logo.png" class="toggle-logo" />
        <div>
          <!-- <p>
            29~30F, 17, Seobinggo-ro,
          </p>
          <p>
            Yongsan-gu, Seoul, Republic of Korea
          </p>
          <p>
            contact@meta-creator.network
          </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';

export default {
  name: 'ToggleMenu',
  mounted() {
    $('.menu-area').click(function() {
      alert('Coming soon');
    });

    $(function () {
      function checkMenuPulseState() {
        var $menuPulse = $('.menu-pulse');
        $menuPulse.addClass('is-learned');
      }
      checkMenuPulseState();

      $(".menu-link").click(function (e) {
        e.preventDefault();

        let menus = document.querySelectorAll('.main-menu');
        menus.forEach((ele, index) => {
          if (ele.style.opacity == "" || ele.style.opacity == 0) {
            setTimeout(() => {
            ele.classList.toggle('animate__animated');
            ele.classList.toggle('animate__backInRight');
            ele.style.opacity = 1;
          }, 100*index)

          }else{
            ele.classList.toggle('animate__animated');
            ele.classList.toggle('animate__backInRight');
            ele.style.opacity = 0;
          }
        })

        localStorage.setItem('learnMenu', 'learned');
        checkMenuPulseState();
        $(".menu-overlay").toggleClass("menu-open");
        $(".menu-toggle").toggleClass("menu-open");
      });
    });


  }
}
</script>

<style scoped lang="scss">
.animate__animated {
  --animate-delay: 0.1s;
}
.overlay-footer {
  position: absolute;

  opacity: 0;
  bottom: 96px;
  left: 66px
}

.overlay-footer p {
  text-align: left;
  margin-left: 69px;
  font-weight: bold;
  font-size: 1rem;
}

.overlay-footer p:first-child {
  margin-top: 20px;
}

.overlay-footer img {
  // width: 336px;
  // height: 41.2px;
}

.overlay-logo {
  width: 129.1px;
  height: 45.9px;
  position: absolute;
  left: 66px;
  top: 60px;
}

.menu-overlay .menu-area {
  font-size: 7rem;
  padding-right: 1em;
  font-weight: 900;
  letter-spacing: -0.4rem;
  color: #1a141c;
  text-align: right;

  cursor: pointer;
  transition: all 0.5s !important;
}

.menu-overlay .menu-area .main-menu {
  height: 110px;

  opacity: 0;
  transition: all 0.5s !important;
}

.menu-open .menu-area .main-menu {
  --animate-duration: 0.5s !important;
  transition: all 0.5s !important;
}

.animate__animated.animate__backInRight {
  --animate-duration: 0.5s;
}

/* ------  Menu Button ------- */
.menu-toggle {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 45px;
  right: 66px;
  margin-left: -36px;
}

.menu-link {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1010;
}

.menu-logo {
  width: inherit;
  height: inherit;
  margin-top: 14px;
  margin-left: 1px;
  text-align: center;
  position: absolute;
  opacity: 1;
  transition: all 400ms ease;
  fill: white;
}

.menu-open .menu-logo {
  fill: black;
}

.menu-icon {
  position: absolute;
  width: 20px;
  height: 14px;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 1px;
}

/* ------  Menu Line ------- */

.menu-line {
  background-color: purple;
  height: 3px;
  width: 100%;
  // left: -2px;
  border-radius: 0px;
  position: absolute;
  // right: 0px;
  opacity: 0;
  transition: all 200ms ease;
  z-index: 1000;
}

.menu-line-1 {
  top: -11px;
  margin: auto;
}

.menu-line-2 {
  top: 18px;
}

.menu-line-3 {
  top: 17px;
}

.menu-link .menu-line-3 {
  opacity: 1;
}

.menu-link .menu-line-2 {
  opacity: 1;
  transform: translateY(-10px);
}

.menu-link .menu-line-1 {
  opacity: 1;
  transform: translateY(10px);
}

.menu-link .menu-logo {
  width: inherit;
  height: inherit;
  text-align: center;
  margin-top: 12px;
  opacity: 0;
  transform: scale(0.8);
  transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotateY(60deg);
}

.menu-link .menu-pulse {
  border: none;
}

.menu-toggle.menu-open .menu-line-1 {
  transform: translateY(17px) translateY(-50%) rotate(-225deg);
}

.menu-toggle.menu-open .menu-line-2 {
  transform: translateY(-15px) translateY(50%) rotate(225deg);
}

.menu-toggle.menu-open .menu-line-3 {
  opacity: 0;
}

/* ------ Menu Circle ------- */
.menu-circle {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  left: -1px;
  bottom: -2px;
  position: absolute;
  border-radius: 50%;
  z-index: 800;
  opacity: 0.2;
  transform: scale(1);
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-box-shadow: 0px 0px 32px 0px rgba(22, 24, 58, 0.05);
  -moz-box-shadow: 0px 0px 32px 0px rgba(22, 24, 58, 0.05);
  box-shadow: 0px 0px 32px 0px rgba(22, 24, 58, 0.05);
}

.menu-pulse {
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  display: block;
  width: inherit;
  height: inherit;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  opacity: 0;

  &.is-learned {
    visibility: hidden;
  }
}

.first-pulse {
  animation: pulse-border 2000ms ease-out infinite;
}

.second-pulse {
  animation: pulse-border 2000ms 400ms ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0;
    border-width: 1px;
  }
}

.menu-toggle .menu-circle {
  background-color: none;
  border: 1px solid #a91dd4;
  opacity: 1;
  transform: scale(0.8);
  transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);

  -webkit-box-shadow: 0px 0px 32px 0px rgba(22, 24, 58, 0.1);
  -moz-box-shadow: 0px 0px 32px 0px rgba(22, 24, 58, 0.1);
  box-shadow: 0px 0px 32px 0px rgba(22, 24, 58, 0.1);
}

.menu-toggle.menu-open .menu-circle {
  border: 1px solid white;
  transform: scale(25);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1), opacity 800ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}


.menu-toggle.menu-open .menu-line {
  background-color: black;
}


.menu-toggle.menu-open .menu-circle {}

/* ------ Menu Overlay ------- */
.menu-overlay {
  background-color: #fff;
  color: #333;
  height: 100%;
  width: 100%;
  position: fixed;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 800;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.menu-overlay.menu-open {
  opacity: 1;
  visibility: visible;
  left: 0px;
  top: 0;
}

.toggle-email {
 font-size: 3rem; 
 letter-spacing: -2px;
 -webkit-text-stroke: 0px !important;
 margin-top: 20px;
}

</style>