<template>
  <div class="mcn-page" id="section2">
    <div class="slide-wrap">
      <div class="content-area">
        <transition name="fade" mode="out-in">
          <component v-bind:is="view" :func="()=> console.log(view)"></component>
        </transition>
      </div>
      <div class="button-area">
        <div class="button-image-wrap" id="developer" @click="contentChange">
          <p id="developer" @click="contentChange">ADVERTISER 
            <font-awesome-icon icon="fa-solid fa-circle-plus" id="developer" @click="contentChange" /></p>
          <img src="../assets/images/developer.jpeg" />
        </div>
        <div class="button-image-wrap" id="creator" @click="contentChange">
          <p id="creator" @click="contentChange">CREATOR 
            <font-awesome-icon icon="fa-solid fa-circle-plus" id="creator" @click="contentChange" />
          </p>
          <img src="../assets/images/creator.jpeg" />
        </div>
        <div class="button-image-wrap" id="community" @click="contentChange">
          <p id="community" @click="contentChange">SUPERFAN 
            <font-awesome-icon icon="fa-solid fa-circle-plus"  id="community" @click="contentChange"/></p>
          <img src="../assets/images/communities.jpeg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WhatMcn from "../components/mcn/WhatMcn.vue";
import IntroDeveloper from "../components/mcn/IntroDeveloper.vue";
import IntroCreator from "../components/mcn/IntroCreator.vue";
import IntroCommunity from "../components/mcn/IntroCommunity.vue";
import $ from 'jquery';

export default {
  data() {
    return {
      view: 'what'
    }
  },
  methods: {
    contentChange(event) {
      this.view = event.target.parentNode.id;
      $(`.button-image-wrap`).css({ 'opacity': '1' });
      $(`.button-image-wrap p`).css({ 'display': 'block' });

      $(`#${this.view}`).css({ 'opacity': '0.5' });
      $(`#${this.view} p`).css({ 'display': 'none' });
    },
    onMinusButtonClick() {
      this.view = 'what';
      $(`.button-image-wrap`).css({ 'opacity': '1' });
      $(`.button-image-wrap p`).css({ 'display': 'block' });
    } 
  },
  components: {
    'what': WhatMcn,
    'developer': IntroDeveloper,
    'creator': IntroCreator,
    'community': IntroCommunity,
  }
}
</script>

<style scoped>
.mcn-page {
  padding-top: 106px;
  width: 100%;
  height: 100vh;
  
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.slide-wrap {
  display: flex;
  justify-content: space-between;
}

.button-area {
  z-index: 4000;
  width: 400px;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: flex-end;
}

.button-area .button-image-wrap {
  width: 320px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;

  transform: rotate(6deg);
  margin-bottom: 20px;
  
  position: relative;
  cursor: pointer;
 }

.button-area .button-image-wrap:first-child {
  transform: scaleX(-1) rotate(-6deg);
}

.button-area .button-image-wrap:nth-of-type(1) {
  margin-right: 20px;
  margin-top: 50px;
}

.button-area .button-image-wrap:nth-of-type(2) {
  margin-top: -14px;
  margin-right: 100px;
}

.button-area .button-image-wrap:nth-of-type(3) {
  margin-top: 12px;
}

.button-area .button-image-wrap img {
  transform: scale(1.5);
  position: absolute;
}

.button-area .button-image-wrap p {
  font-size: 28px;
  color: #fff;
  z-index: 4444;

  position: absolute;
  font-family: 'industry';
  font-weight: 900;
  top: 84px;
}

.button-area .button-image-wrap:first-child p {
  transform: scaleX(-1);
  left: 60px;
}

.button-area .button-image-wrap:nth-of-type(2) p {
  left: 84px;
}

.button-area .button-image-wrap:nth-of-type(3) p {
  left: 80px;
}

.button-area .button-image-wrap:first-child img {
  transform: scale(1.5);
  position: absolute;
  right: -40px;
  top: 53px;
}

.button-area .button-image-wrap:nth-of-type(2) img {
  transform: scale(1.5);
  position: absolute;
  top: 24px;
  left: 10px;
}

.button-area .button-image-wrap:nth-of-type(3) img {
  transform: scale(1.5);
  position: absolute;
  top: -46px;
}
.mcn-page .slide-wrap {
  width: 1300px;
  height: 722px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>