<template>
  <div class="loading-page">
    <img src="../assets/images/MCN-02.png" class="slideUp_logo animate__animated animate__zoomIn" />
  </div>
</template>

<script>

import $ from "jquery";
export default {
  mounted() {
    setTimeout(() => {
      $('.loading-page').slideUp();
    }, 1000);
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 600px) {
  .slideUp_logo {
    width: 60vw;
  }
}

.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(26, 20, 28);
  z-index: 9999;
}


</style>
