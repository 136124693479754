<template>
  <div class="mobile-pages" style="overflow: hidden;">
    <div class="mobile-mcnt-page">
      <video src="../../assets/video/m-etc_back.mp4" muted autoplay="true" loop="true" playsinline="true"></video>

      <div class="content-area">
        <img src="../../assets/images/mcnt-logo.png" class="transition-text transition-text-desc" />
        <div class="text-area transition-text-desc transition-text">
          <p class="mcnt-desc">
            Meta Creator Network + Token
          </p>
          <p class="mcnt-desc-sub">
            MNCT is the utility token of the MCN ecosystem.
          </p>
        </div>
      </div>

      <div class="blur_div"></div>
      <div class="blur_div2"></div> 
    </div>
  </div>
</template>

<script>

export default {
  mounted() {
    let observer = new IntersectionObserver((e) => {
      e.forEach(element => {
        if (element.isIntersecting) {
          element.target.style.opacity = 1;
          element.target.classList.add('animate__animated', 'animate__slideInUp');
        } else {
          element.target.style.opacity = 0;
          element.target.classList.remove('animate__animated', 'animate__slideInUp');
        }
      });
    });

    let div = document.querySelectorAll('.transition-text-desc');
    observer.observe(div[0]);
    observer.observe(div[1]);
  }
}
</script>

<style scoped>

.mobile-mcnt-page {
  width: 100%;
  height: 100%;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-mcnt-page video{
  width:100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  object-fit: cover;
  object-position: center;
}


.mobile-mcnt-page .content-area {
  width:100%;
  overflow: hidden;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-mcnt-page .text-area { 
  margin-left: 0rem;
  margin-top: 1rem;
  /* opacity: 1 !important; */
  z-index:4444;
}

.mobile-mcnt-page .content-area img{
  width: 70vw;
  max-width: 450px;
}

.mobile-mcnt-page .content-area .mcnt-desc {
  font-size: 1.2rem;
  color: white;
  font-weight: 600;
}

.mobile-mcnt-page .content-area .mcnt-desc-sub {
  opacity: 1;
  font-size: 1rem;
  color: white;
  font-weight: 100;
}

.blur_div {
  width: 120vw;
  height: 40px;
  position: absolute;
  top: -20px;
  left: -20px;

  background: #1a141c;
  filter: blur(10px);
  z-index: 1;
}

.blur_div2 {
  width: 120vw;
  height: 50px;
  position: absolute;
  bottom: -27px;

  background: #1a141c;
  filter: blur(10px);
  z-index: 1;
}


</style>