import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css';
import './assets/index.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowDown, faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import gsap from 'gsap';

library.add(faArrowDown, faCirclePlus, faCircleMinus)

// Vue.use(VueSplide);
Vue.use(gsap)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
