import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MobileHome from '../views/mobile/MobileHome'
import $ from 'jquery';

Vue.use(VueRouter)

var is_mobile = false;
var user = navigator.userAgent;
  
if( user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1 ) {
   is_mobile = true;
}

if($('body').width() <= 1024) {
  is_mobile = true; // table vertical
}

console.log("is mobile", is_mobile);

const routes = [
  {
    path: '/',
    name: 'home',
    component: is_mobile ? MobileHome : HomeView, 
  },
]

    // path: '/about',
    // name: 'about',
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')

    
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
