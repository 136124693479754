<template>
  <div class="main-page">
    <div class="partners">
      <div class="partners-title">
        <p class="transition-text transition-partners">
          Partners
        </p>
      </div>
      <div class="logo-area">
        <div class="logo" v-for="(item, index) in logoArr" :key="index" >
          <img class="transition-logos transition-text" :src="require(`@/assets/images/${item}.png`)" />
        </div>
      </div>
      <div class="gradient-area"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
// @ is an alias to /src pc
// pc
export default {
  data() {
    return {
      logoArr: [
        "oneplanet",
        "wemade",
        "thesandbox",
        "polygon",
        "ozys",
        "xlgames",
        "supercat",
        "hexlant",
        "klaytn",
        "grampus",
        "sbxg",
        "mbc",
      ]
    }
  },
  mounted() {
    let bodyWidth = $("body").width();
    if(bodyWidth <= 1120) {
      $('.partners').css({ paddingTop: 0 });
    }

    let observer = new IntersectionObserver((e)=> {
      e.forEach((element, index) => {
        if (element.isIntersecting) {
          if(element.target.classList.contains('transition-logos')) {
            setTimeout(() => {
              element.target.style.opacity = 1;
              element.target.classList.add('animate__animated','animate__slideInUp');
            }, 200 * index);
          }
          else{
            element.target.style.opacity = 1;
            element.target.classList.add('animate__animated','animate__slideInUp');
          }
          
        }else{
          element.target.style.opacity = 0;
          element.target.classList.remove('animate__animated','animate__slideInUp');
        }
      });
    });

    
    let logos = document.querySelectorAll('.transition-logos');
    logos.forEach(logo => observer.observe(logo))

    let partners = document.querySelector('.transition-partners');
    observer.observe(partners);
  }

}
</script>

<style scoped>
.partners {
  z-index: 3333;
  position: relative;

  width: 1400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 120px;
  /* padding-top: 160px; */
}

.partners-title {
  color: #fff;
  z-index: 4444;
  font-size: 9rem;
  font-weight: 900;
  -webkit-text-stroke: 3px white;
}

.gradient-area {
  position: absolute;
  width: 1300px;
  height: 760px;
  top: 250px;
  left: 30px;
  transform: rotate(6deg);
  border-radius: 40px;
  background-image: linear-gradient(1deg, rgba(171, 40, 212, 0) 52%, #550f6a 101%);
}

.logo-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 4444;
}

.logo-area .logo {
  display: flex;
  justify-content: center;
  align-items: center;

  border: solid 1px rgba(134, 119, 95, 0.16);
  width: 293px;
  height: 145px;
}
</style>
