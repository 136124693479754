<template>
  <div class="mobile-pages">
    <div class="footer">
      <div class="background"></div>
      <div class="wrap footer-ele">
        <img src="../../assets/images/MCN-01.png" class="footer-logo" style="width:200px;" />
        <!-- <p style="margin-top: 10px" class="footer-desc">
          29~30F, 17, Seobinggo-ro, Yongsan-gu,
        </p> -->
        <!-- <p class="footer-desc">
          Seoul, Republic of Korea
        </p> -->

        <a href="mailto:contact@meta-creator.network">
        <div class="input-wrap" style="margin-top: 10px">
            <p class="footer-contact"> Contact to us </p>
            <div class="circle animate__animated animate__heartBeat animate__infinite">
              <font-awesome-icon icon="fa-solid fa-arrow-down" class="arrow " />
            </div>
          </div>
        </a>

        <p class="footer-desc footer-desc-email">
          contact@meta-creator.network
        </p>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import $ from "jquery";

export default {
  name: 'JoinView',
  methods: {
    inputEnter() {
      const $input = $('#mobile-footer-input');
      var inputValue = $input.val();

      if(inputValue == "") {
        return ;
      }

      $input.val("");
      // const api = `/api/email/${inputValue}`;
      // https://meta-creator.network/api/email/:email:
      // axios.get(api).then((res)=> {
      //   console.log(res);
      //   alert('subscribed');
      // })
      // .catch((err) => {
      //   alert(err);
      // })
    }
  },
  mounted() {
    if($("body").width() >= 600) {
      
      $('.footer .wrap p').css({ fontSize: "1.4rem"});
      $('.input-wrap').css({ fontSize: "1.4rem"});
      $(".footer-contact").css({ fontSize: '2rem'})
      $('.circle').css({ marginTop: '4rem'});
      $('#mobile-footer-input').css({ fontSize: '2rem', marginTop: '4rem', width: '300px'});
      $('#mobile-footer-input::placeholder').css({ fontSize: '2.5rem'})
      // $('.image-button-wrap').css({ width: "70%", height: "120px" });
      // $('.image-button').css({ height: "120px" });
      // $('.image-button p').css({ fontSize: '1.5rem' });
    }

    const self = this;

    $('.circle').click(function() { 
      self.inputEnter();
    });

    let observer = new IntersectionObserver((e) => {
      e.forEach((element, index) => {
        if (element.isIntersecting) {
          setTimeout(() => {
            element.target.classList.add('transition-shakes');
          }, 50 * index);
        }else{
          element.target.classList.remove('transition-shakes');
        }
      });
    });

    let footerEle = document.querySelectorAll('.footer-ele');
    footerEle.forEach(ele => observer.observe(ele))

  }
}
</script>

<style scoped>
.mobile-pages {
  height: 60vh;
}
.footer {
  padding-top: 9rem;

  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;

  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer .wrap .footer-desc {
  color: white;
  font-size: 0.9rem;
}

.footer .wrap .footer-desc-email {
  font-size: 1.2rem;
  margin-top: 2rem;
  font-weight: 900;
}

.footer .wrap .footer-logo {
  width: 60vw;
}

.footer .input-wrap {
  margin-top: -50px;
}

.footer-contact {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 900;
  margin-top: 3.5rem;
}

.input-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer .wrap input {
  /* width: 400px; */
  background: none;
  color: black;
  width: 200px;
  font-family: 'articulat-cf';
  font-weight: 900;
  font-size: 1rem;
  padding-top : 10px;
  border-bottom: 2px solid black;
  margin-top: 50px;
  margin-left: 30px;
}

input:focus {
  outline: none !important;
}

input::placeholder {
  color: black;
  font-style: 'articulat-cf';
  font-weight: 900;
  font-size: 1.2rem;
}

.circle {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;

  margin-top: 50px;
  margin-left: 10px;
}

.arrow {
  position: absolute;
  top: 8px;
  left: 10px;
  color: white;

  transform: rotate(-90deg) !important;
  font-size: 20px;
}


.background {
  position: absolute;
  background-color: #a91dd4;
  transform: rotate(8deg);
  top: 100px;
  z-index: -1;
  width: 200%;
  height: 140%;
}

.logo_condition {
  width: 100%;
  height: 5vh;
  background: none;
  position: absolute;
  bottom: 0px;
}
</style>