<template>
  <div class="card-wrap">
    <div class="card">
      <video id="main-video" onloadeddata src="../assets/video/main.mp4" muted autoplay="true" loop="true" playsinline="true"></video>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Hover3DEffect',
  mounted() {
    var card = $(".card");

    $(".main").on("mousemove", function (e) {
      var ax = -($(window).innerWidth() / 2 - e.pageX) / 20;
      var ay = ($(window).innerHeight() / 2 - e.pageY) / 10;
      card.attr("style", "transform: rotateY(" + ax + "deg) rotateX(" + ay + "deg);-webkit-transform: rotateY(" + ax + "deg) rotateX(" + ay + "deg);-moz-transform: rotateY(" + ax + "deg) rotateX(" + ay + "deg)");
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-wrap {
  width: 1400px;
  position: absolute;
  z-index: 1002;
  perspective: 1000px;
  transform-style: preserve-3d;
  display: flex;
}

.card {
  transform: rotate(6deg);
  pointer-events: none;
  width: 800px;
  height: 560px;
  // height: 504px;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  display: flex;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
  position: relative;
}

.card video {
  border-radius: 8px;
  // transform: scale(0.4);
  position: absolute;
  object-fit: fill;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 1;
}
</style>