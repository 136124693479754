<template>
  <div class="mobile-pages" style="overflow: hidden;" id="section2">
    <div class="blur_div"></div>
    <div class="blur_div2"></div>
    <div class="what-mcn" style="color: #fff;">
      <div class="gradient-background"></div>

      <div class="text-area">
        <div class="what-title">
          <p class="transition-text">WHAT</p>
          <p class="transition-text">IS <span class="bora">MCN?</span></p>
        </div>
        <div class="desc" style="margin-top: -4px;">
          <p>Meta Creator Network is web3 friendly, platform agnostic
        creator membership to connect creators and superfans
          directly with advertisers who want to reach them.</p>
        </div>
      </div>
    </div>
    <div class="app">
      <div class="infoList">
        <div class="info__wrapper">
          <div class="info current--info">
            <div class="slide-title developer">
              ADVERTISER
              <font-awesome-icon  class="minus" icon="fa-solid fa-circle-minus"  />
            </div>
            <div class="description">
              <p><strong>ADVERTISER</strong></p>
              <p>upload ad missions on the mission board to advertise</p>
              <p>their dApp and content. Setting various goals and rewards,</p>
              <p>developers acquire activate users from missions.</p>
            </div>
          </div>

          <div class="info next--info">
            <div class="slide-title creator">
              CREATOR
              <font-awesome-icon  class="minus" icon="fa-solid fa-circle-minus"  />
            </div>
            <div class="description">
              <p><strong>Creators</strong></p>
              <p>are the main influencer of the creator community.</p>
              <p>They select and operate ad missions posted by developers</p>
              <p>to achieve rewards within their community.</p>
            </div>
          </div>

          <div class="info previous--info">
            <div class="slide-title">
              SUPERFAN
              <font-awesome-icon icon="fa-solid fa-circle-minus" class="minus" />
            </div>
            <div class="description">
              <p><strong>SUPERFAN</strong></p>
              <p>represents the identity of the creator</p>
              <p>in themes of games, art, food and etc.</p>
              <p>Community members complete mission tasks</p>
              <p>from the creator</p>
              <p>and gets reward distributions as compensation.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="cardList">
      <!-- 
        <button class="cardList__btn btn btn--left">
          <div class="icon">
            <svg>
              <use xlink:href="#arrow-left"></use>
            </svg>
          </div>
        </button>
      -->

        <div class="cards__wrapper">
          <div class="card current--card">
            <div class="card__image">
              <video src="../../assets/video/m-developer.mp4" muted="true" autoplay="true" loop="ture" playsinline="true"></video>
            </div>
          </div>

          <div class="card next--card">
            <div class="card__image">
              <video src="../../assets/video/m-creator.mp4" muted="true" loop="ture" playsinline="true"></video>
            </div>
          </div>

          <div class="card previous--card">
            <div class="card__image">
              <video src="../../assets/video/m-community.mp4" muted="true" loop="ture" playsinline="true"></video>
            </div>
          </div>
        </div>
      </div>

      <!-- 
        <button class="cardList__btn btn btn--right">
          <div class="icon">
            <svg>
              <use xlink:href="#arrow-right"></use>
            </svg>
          </div>
        </button>
      -->

      <div class="app__bg">
        <div class="app__bg__image current--image" id="">
          <img src="../../assets/images/m-developer.jpeg" alt="" />
        </div>
        <div class="app__bg__image next--image">
          <img src="../../assets/images/m-creator.jpeg" alt="" />
        </div>
        <div class="app__bg__image previous--image">
          <img src="../../assets/images/m-communities.jpeg" alt="" />
        </div>
      </div>

    </div>
    <div class="image-button-wrap">
      <div class="image-button developer">
        <p>ADVERTISER</p>
        <img src="../../assets/images/m-developer.jpeg" alt="" />
      </div>
      <div class="image-button creator">
        <p>CREATOR</p>
        <img src="../../assets/images/m-creator.jpeg" alt="" />
      </div>
      <div class="image-button communities">
        <p>SUPERFAN</p>
        <img src="../../assets/images/m-communities.jpeg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>

import $ from "jquery";
import gsap from "gsap";

export default {
  methods: {
    viewChange() {
      $('.app').hide();
      $('.what-mcn').show();
    }
  },
  mounted() {

    //.what-mcn .text-area .desc
    if($("body").width() >= 600) {
      $('.what-mcn .text-area .desc').css({ "fontSize": "1.2rem"});
      $('.image-button-wrap').css({ width: "70%", height: "120px" });
      $('.image-button').css({ height: "120px" });
      $('.image-button p').css({ fontSize: '1.5rem' });
    }

    $('.minus').click(function() {
      $('.app').hide();
      $('.what-mcn').show();
      $('.image-button').css({ "opacity": '1' });
      $('.image-button p').show();
      
    });

    let observer = new IntersectionObserver((e) => {
      e.forEach(element => {
        if (element.isIntersecting) {
          element.target.style.opacity = 1;
          element.target.classList.add('animate__animated', 'animate__slideInUp');
        } else {
          element.target.style.opacity = 0;
          element.target.classList.remove('animate__animated', 'animate__slideInUp');
        }
      });
    });

    let div = document.querySelectorAll('.transition-text');
    observer.observe(div[0]);
    observer.observe(div[1]);

    $(".app").hide();

    $(".image-button-wrap").click(function () {
      $('.what-mcn').hide();
      $(".app").show();
    });

    let currentVideo = $('.current--card').find('video')[0];

    const cardsContainerEl = document.querySelector(".cards__wrapper");
    const appBgContainerEl = document.querySelector(".app__bg");

    const cardInfosContainerEl = document.querySelector(".info__wrapper");

    function swapCards(direction) {
      const currentCardEl = cardsContainerEl.querySelector(".current--card");
      const previousCardEl = cardsContainerEl.querySelector(".previous--card");
      const nextCardEl = cardsContainerEl.querySelector(".next--card");

      const currentBgImageEl = appBgContainerEl.querySelector(".current--image");
      const previousBgImageEl = appBgContainerEl.querySelector(".previous--image");
      const nextBgImageEl = appBgContainerEl.querySelector(".next--image");

      changeInfo(direction);
      swapCardsClass();

      removeCardEvents(currentCardEl);

      function swapCardsClass() {
        currentCardEl.classList.remove("current--card");
        previousCardEl.classList.remove("previous--card");
        nextCardEl.classList.remove("next--card");

        currentBgImageEl.classList.remove("current--image");
        previousBgImageEl.classList.remove("previous--image");
        nextBgImageEl.classList.remove("next--image");

        currentCardEl.style.zIndex = "50";
        currentBgImageEl.style.zIndex = "-2";

        if (direction === "right") {
          previousCardEl.style.zIndex = "20";
          nextCardEl.style.zIndex = "30";

          nextBgImageEl.style.zIndex = "-1";

          currentCardEl.classList.add("previous--card");
          previousCardEl.classList.add("next--card");
          nextCardEl.classList.add("current--card");

          currentBgImageEl.classList.add("previous--image");
          previousBgImageEl.classList.add("next--image");
          nextBgImageEl.classList.add("current--image");
        } else if (direction === "left") {
          previousCardEl.style.zIndex = "30";
          nextCardEl.style.zIndex = "20";

          previousBgImageEl.style.zIndex = "-1";

          currentCardEl.classList.add("next--card");
          previousCardEl.classList.add("current--card");
          nextCardEl.classList.add("previous--card");

          currentBgImageEl.classList.add("next--image");
          previousBgImageEl.classList.add("current--image");
          nextBgImageEl.classList.add("previous--image");
        }
      }
    }

    function changeInfo(direction) {
      let currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
      let previousInfoEl = cardInfosContainerEl.querySelector(".previous--info");
      let nextInfoEl = cardInfosContainerEl.querySelector(".next--info");

      swapInfosClass();

      function swapInfosClass() {
        currentInfoEl.classList.remove("current--info");
        previousInfoEl.classList.remove("previous--info");
        nextInfoEl.classList.remove("next--info");

        if (direction === "right") {
          currentInfoEl.classList.add("previous--info");
          nextInfoEl.classList.add("current--info");
          previousInfoEl.classList.add("next--info");
        } else if (direction === "left") {
          currentInfoEl.classList.add("next--info");
          nextInfoEl.classList.add("previous--info");
          previousInfoEl.classList.add("current--info");
        }
      }
    }

    function updateCard(e) {
      const card = e.currentTarget;
      const box = card.getBoundingClientRect();
      const centerPosition = {
        x: box.left + box.width / 2,
        y: box.top + box.height / 2,
      };
      let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
      gsap.set(card, {
        "--current-card-rotation-offset": `${angle}deg`,
      });
    }

    function resetCardTransforms(e) {
      const card = e.currentTarget;
      const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
      gsap.set(card, {
        "--current-card-rotation-offset": 0,
      });
      gsap.set(currentInfoEl, {
        rotateY: 0,
      });
    }

    function initCardEvents() {
      const currentCardEl = cardsContainerEl.querySelector(".current--card");
      currentCardEl.addEventListener("pointermove", updateCard);
      currentCardEl.addEventListener("pointerout", (e) => {
        resetCardTransforms(e);
      });
    }

    initCardEvents();

    function removeCardEvents(card) {
      card.removeEventListener("pointermove", updateCard);
    }

    function init() {
      let tl = gsap.timeline();

      tl.to(cardsContainerEl.children, {
        delay: 0.15,
        duration: 0.5,
        stagger: {
          ease: "power4.inOut",
          from: "right",
          amount: 0.1,
        },
        "--card-translateY-offset": "0%",
      })
    }


    $('.image-button').click(function () {
      let $title = $('.current--info .slide-title');
      currentVideo = $('.current--card').find('video')[0];

      $('.image-button').css({ "opacity": '1' });
      $('.image-button p').show();

      $(this).css({ "opacity": '0.5' });
      $(this).find('p').hide();

      if (this.classList.contains('developer')) {
        if ($title[0].classList.contains('developer')) {
          // 
        } else {
          currentVideo.pause();

          if ($title[0].classList.contains('creator')) {
            swapCards("left");
          } else {
            swapCards("right");
          }

          setTimeout(() => {
            currentVideo = $('.current--card').find('video')[0];
            currentVideo.play();
          }, 100)

        }
      }

      if (this.classList.contains('creator')) {
        if ($title[0].classList.contains('creator')) {
          // 
        } else {
          currentVideo.pause();

          if ($title[0].classList.contains('developer')) {
            swapCards("right");
          } else {
            swapCards("left");
          }

          setTimeout(() => {
            currentVideo = $('.current--card').find('video')[0];
            currentVideo.play();
          }, 100)

        }
      }

      if (this.classList.contains('communities')) {
        if ($title[0].classList.contains('communities')) {
          // 
        } else {
          currentVideo.pause();

          if ($title[0].classList.contains('developer')) {
            swapCards("left");
          } else {
            swapCards("right");
          }

          setTimeout(() => {
            currentVideo = $('.current--card').find('video')[0];
            currentVideo.play();
          }, 100)

        }
      }
    });


    $('.card').click(function () {
      if (!this.classList.contains('current--card')) {
        currentVideo.pause();

        if (this.classList.contains('next--card')) {
          swapCards("right");
        } else {
          swapCards("left");
        }

        setTimeout(() => {
          currentVideo = $(this).find('video')[0];
          currentVideo.play();

          $('.image-button').css({ "opacity": '1' });
          $('.image-button p').show();

          let $title = $('.current--info .slide-title');
          if ($title[0].classList.contains('creator')) {
            const $temp = $('.image-button-wrap').find('.creator');
            $temp.css({ "opacity": '0.5' });
            $temp.find('p').hide();
          } else if ($title[0].classList.contains('developer')) {
            const $temp = $('.image-button-wrap').find('.developer');
            $temp.css({ "opacity": '0.5' });
            $temp.find('p').hide();
          } else { // community
            const $temp = $('.image-button-wrap').find('.communities');
            $temp.css({ "opacity": '0.5' });
            $temp.find('p').hide();
          }

        }, 100)
      }


      // currentVideo[0].play();
      // console.log(currentVideo);
    })

    init();
    // const $temp = $('.image-button-wrap').find('.developer');
    // $temp.css({ "opacity": '0.5' });
    // $temp.find('p').hide();

  }
}
</script>

<style scoped lang="scss">
.image-button-wrap {
  display: flex;

  width: 98%;

  margin-left: 1%;
  margin-right: 1%;

  position: absolute;
  bottom: 10px;
  z-index:4444;
}

.image-button-wrap .image-button {
  width: 33%;
  height: 80px;
  overflow: hidden;
  padding: 6px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-button-wrap .image-button img {
  border-radius: 12px;
  width: 94%;
  height: 100%;
  position: absolute;
  z-index: 3333;
}

.image-button p {
  z-index: 4444;
  color: #fff;
  margin-top: 56px;
  font-family: 'industry';
  font-weight: 600;
}

.app {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  --card-width: 240px;
  --card-height: 400px;
  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
}

.app__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -5;
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
  overflow: hidden;
}

.app__bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1;
  opacity: 0.8;
}

.app__bg__image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(var(--image-translate-offset, 0));
  width: 100%;
  height: 100%;
  transition: transform 1000ms ease, opacity 1000ms ease;
  overflow: hidden;
}

.app__bg__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.app__bg__image.current--image {
  opacity: 1;
  --image-translate-offset: 0;
}

.app__bg__image.previous--image,
.app__bg__image.next--image {
  opacity: 0;
}

.app__bg__image.previous--image {
  --image-translate-offset: -25%;
}

.app__bg__image.next--image {
  --image-translate-offset: 25%;
}

.cardList {
  position: absolute;
  width: calc(3 * var(--card-width));
  height: auto;
}

.cardList__btn {
  --btn-size: 35px;
  width: var(--btn-size);
  height: var(--btn-size);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.cardList__btn.btn--left {
  left: -5%;
}

.cardList__btn.btn--right {
  right: -5%;
}

.cardList__btn .icon {
  width: 100%;
  height: 100%;
}

.cardList__btn .icon svg {
  width: 100%;
  height: 100%;
}

.cardList .cards__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.card {
  --card-translateY-offset: 0%;
  // --card-translateY-offset: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(var(--card-translateX-offset)) translateY(var(--card-translateY-offset)) rotateY(var(--card-rotation-offset)) scale(var(--card-scale-offset));
  display: inline-block;
  width: var(--card-width);
  height: var(--card-height);
  transition: transform var(--card-transition-duration) var(--card-transition-easing);
  user-select: none;
}

.card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1;
  transition: opacity var(--card-transition-duration) var(--card-transition-easing);
  opacity: calc(1 - var(--opacity));
}

.card__image {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.card__image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0px;
  top: 0px;
}

.card__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card.current--card {
  --current-card-rotation-offset: 0;
  --card-translateX-offset: 0;
  --card-rotation-offset: var(--current-card-rotation-offset);
  --card-scale-offset: 1.2;
  --opacity: 0.8;
}

.card.previous--card {
  --card-translateX-offset: calc(-1 * var(--card-width) * 1.1);
  --card-rotation-offset: 25deg;
}

.card.next--card {
  --card-translateX-offset: calc(var(--card-width) * 1.1);
  --card-rotation-offset: -25deg;
}

.card.previous--card,
.card.next--card {
  --card-scale-offset: 0.9;
  --opacity: 0.4;
}

.infoList {
  z-index: 3333;
  position: absolute;
  // width: calc(3 * var(--card-width));
  width: 84vw;
  height: var(--card-height);
  // pointer-events: none;
}

.infoList .info__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.info {
  // margin-bottom: calc(var(--card-height) / 8);
  // margin-left: calc(var(--card-width) / 1.5);
  transform: translateZ(2rem);
  transition: transform var(--card-transition-duration) var(--card-transition-easing);
}

.slide-title {
  text-transform: uppercase;
  width: 62vw;
  height: 3.6rem;
  background: #1a141c;
  border-radius: 6px;
  color: #a91dd4;
  font-family: 'industry';
  font-weight: 900;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
}

.info .location {
  font-weight: 800;
}

.info .location {
  --mg-left: 40px;
  --text-size-offset: 0.12;
  font-weight: 600;
  margin-left: var(--mg-left);
  margin-bottom: calc(var(--mg-left) / 2);
  padding-bottom: 0.8rem;
}

.info .location::before,
.info .location::after {
  content: "";
  position: absolute;
  background: #fff;
  left: 0%;
  transform: translate(calc(-1 * var(--mg-left)), -50%);
}

.info .location::before {
  top: 50%;
  width: 20px;
  height: 5px;
}

.info .location::after {
  bottom: 0;
  width: 60px;
  height: 2px;
}

.info .description {
  margin-top: 0.5rem;
  color: #fff;
  font-size: 0.8rem;
}

.info.current--info {
  opacity: 1;
  display: block;
}

.info.previous--info,
.info.next--info {
  opacity: 0;
  display: none;
}

.loading__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
  z-index: 200;
}

.loading__wrapper .loader--text {
  color: #fff;
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 1.4rem;
}

.loading__wrapper .loader {
  position: relative;
  width: 200px;
  height: 2px;
  background: rgba(255, 255, 255, 0.25);
}

.loading__wrapper .loader span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: red;
  transform: scaleX(0);
  transform-origin: left;
}

@media only screen and (min-width: 800px) {
  :root {
    --card-width: 250px;
    --card-height: 400px;
  }
}

.support {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  display: flex;
}

.support a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.8rem;
  backface-visibility: hidden;
  transition: all 150ms ease;
}

.blur_div {
  width: 120vw;
  height: 50px;
  position: absolute;
  top: -15px;
  left: -30px;
  background: #1a141c;
  filter: blur(10px);
  z-index: -1;
}

.blur_div2 {
  width: 120vw;
  height: 50px;
  position: absolute;
  bottom: -15px;
  left: -30px;
  background: #1a141c;
  filter: blur(10px);
  z-index: -1;
}


.what-mcn {
  width: 80vw;
  max-width: 33rem;
  height: 50vh;
  position: relative;
}

.what-mcn .gradient-background {
  position: absolute;
  top: 3.5rem;
  // top: 76px;
  // left: 26px;

  z-index: 1;
  //716 564
  width: 80vw;
  max-width: 33rem;
  
  height: 70%;
  min-height: 300px;
  border-radius: 40px;

  background: rgb(26, 20, 28);
  background: linear-gradient(230deg, rgba(26, 20, 28, 1) 40%, rgba(38, 19, 44, 1) 50%, rgba(85, 15, 106, 1) 94%, rgba(85, 15, 106, 1) 100%);

  transform: rotate(6deg);
}

.what-mcn .text-area {
  width: 100%;
  max-width:33rem;
  position: absolute;
  color: #fff;

  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 6rem;
}

.what-mcn .text-area .what-title {
  padding: 0px;
  margin: 0px;
  width: 80%;
  max-width: 18rem;
  font-size: 3.5rem;
  font-weight: 900;
  -webkit-text-stroke: 3px white;
}

.what-mcn .text-area .what-title p:first-child {}

.what-mcn .text-area .what-title p:nth-of-type(2) {
  margin-top: -1.5rem;
  // margin-right: -68px;
  text-align: right;
}

.what-mcn .text-area .desc {
  font-size: 0.8rem;
  margin: 0px;

  margin-top: 1rem;
  margin-left: 1rem;

}

.bora {
  color: #a91dd4;
  -webkit-text-stroke: 3px #a91dd4;
  margin-right: 10px;
}

.minus {
  z-index: 3333;
  margin-left: 6px;
  margin-top: -4px;
}
</style>
