<template>
  <div class="mcn-pages-contents">
    <div class="mcn-pages-video-box">
      <video src="../../assets/video/creator.mp4" autoplay loop playsinline="true"></video>
    </div>
    <div class="text">
      <div class="close-menu" @click="this.$parent.onMinusButtonClick" style="width:410px; margin-left:10px;">
        CREATOR
        <font-awesome-icon icon="fa-solid fa-circle-minus" class="minus" />
      </div>
      <div class="desc ">
        <p><strong>Creators</strong> are the main influencer of the creator community.</p>
        <p>They select and operate ad missions posted by developers</p>
        <p>to achieve rewards within their community.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
