<template>
  <div class="mobile-pages">
    <div class="mobile-partners-page">
      <div class="partners-title">
        <p class="transition-text transition-partners">
          Partners
        </p>
      </div>
      <div class="logo-area">
        <div class="logo" v-for="(item, index) in logoArr" :key="index" >
          <img class="transition-logos transition-text" :src="require(`@/assets/images/${item}.png`)" />
        </div>
      </div>
    </div>
   

    <div class="gradient-area"></div>
  </div>
</template>

<!-- 
  <div class="mobile-pages">
    <div class="mobile-mcn-page"> -->
<script>
import $ from 'jquery';
// @ is an alias to /src

export default {
  data() {
    return {
      logoArr: [
        "oneplanet",
        "wemade",
        "thesandbox",
        "polygon",
        "ozys",
        "xlgames",
        "supercat",
        "hexlant",
        "klaytn",
        "grampus",
        "sbxg",
        "mbc",
      ]
    }
  },
  mounted() {
    let bodyWidth = $('body').width();

    // if(bodyWidth <= 1120) {
    //   $(".desc-title").css({ fontSize: '6rem'});
    //   $('.splide').css({ width: '1500px' });
    // }
    
    $('.gradient-area').css({
      left: (bodyWidth/2)-($('.gradient-area').width()/2)-(bodyWidth/25)
    })

    let observer = new IntersectionObserver((e)=> {
      e.forEach((element, index) => {
        if (element.isIntersecting) {
          if(element.target.classList.contains('transition-logos')) {
            setTimeout(() => {
              element.target.style.opacity = 1;
              element.target.classList.add('animate__animated','animate__slideInUp');
            }, 200 * index);
          }
          else{
            element.target.style.opacity = 1;
            element.target.classList.add('animate__animated','animate__slideInUp');
          }
          
        }else{
          element.target.style.opacity = 0;
          element.target.classList.remove('animate__animated','animate__slideInUp');
        }
      });
    });

    
    let logos = document.querySelectorAll('.transition-logos');
    logos.forEach(logo => observer.observe(logo))

    let partners = document.querySelector('.transition-partners');
    observer.observe(partners);
  }

}
</script>

<style scoped>
.mobile-partners-page {
  z-index: 3333;
  position: relative;

  width: 80vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  /* padding-top: 140px; */
  /* padding-top: 120px; */
}

.partners-title {
  color: #fff;
  z-index: 4444;
  font-size: 3.5rem;
  font-weight: 900;
  -webkit-text-stroke: 1px white;
}

.gradient-area {
  position: absolute;
  top: 21.5vh;
  /* left: calc(50vw - 350px); */
  width: 80vw;
  max-width: 700px;
  height: 70vh;
  /* height: 760px; */
  /* top: 250px;
  left: 30px; */
  transform: rotate(6deg);
  border-radius: 14px;
  background-image: linear-gradient(1deg, rgba(171, 40, 212, 0) 52%, #550f6a 101%);
}

.logo-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 4444;
}

.logo-area .logo {
  display: flex;
  justify-content: center;
  align-items: center;

  border: solid 1px rgba(134, 119, 95, 0.16);
  width: 130px;
  /* height: 86px; */
  height: 76px;
  padding: 20px;
}

.logo-area:first-child .logo img {
  width: 120px;
}

.logo-area .logo:nth-of-type(5) img {
  width: 60px;
  height: 22px;
}

.logo-area .logo img {
  width: 80px;
  /* height: 30px; */
}
</style>
