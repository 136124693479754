<template>
  <div id="app">
    <LoadingPage />
    <nav class="mainNav" v-if="!is_mobile">
      <a href="/">
        <img src="./assets/images/MCN-03.png" id="logo3" />
        <img src="./assets/images/MCN-04.png" id="logo4" style="display: none"/>
      </a>
      <ToggleMenu />
    </nav>
    <nav v-else class="mobile_nav">
      <a href="/">
        <img src="./assets/images/MCN-03.png" class="mobile_logo_1" />
        <img src="./assets/images/MCN-04.png" class="mobile_logo_2" style="display: none"/>
      </a>
      <MobileToggleMenu />
    </nav>
    <router-view />
  </div>
</template>

<style scoped>
.mobile_nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px 10px;
  border-bottom: 1px solid #484343;

  position: fixed;
  z-index: 8888;
}
.mobile_logo_1, .mobile_logo_2{
  width: 64px;
  /* padding-top: 50px; */
}
svg {
  fill: white;
}
.mainNav {
  padding-top: 60px;
  position: fixed;
  z-index: 8888;
  width: 100%;

  display: flex;
}
.mainNav img {
  margin-left: 66px;
  width: 129px;
  height: 46px;
}
</style>

<script>
import ToggleMenu from "./components/ToggleMenu.vue";
import MobileToggleMenu from "./components/MobileToggleMenu.vue";
import LoadingPage from "./components/LoadingPage.vue";

import $ from 'jquery';

export default {
  name: 'App',
  data() {
    return {
      is_mobile: false,
    } 
  },
  components: {
    MobileToggleMenu,
    ToggleMenu,
    LoadingPage
  },
  created() {
    var user = navigator.userAgent;

    if($('body').width() <= 1024) {
      this.is_mobile = true; // table vertical
    }

    console.log(user);
    console.log($('body').width());

    if( user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1 || user.indexOf("iPad") > -1 ) {
      this.is_mobile = true;
    }

  },
  mounted() {

    console.log("is mobile", this.is_mobile);

  }
}
</script>

