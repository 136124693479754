<template>
  <div class="main-page">
    <div class="footer">
      <div class="background"> </div>
      <!-- <img src="../assets/images/footer-background.png" class="background" /> -->
      <div class="wrap">
        <div class="footer-left footer-ele">
          <img src="../assets/images/MCN-01.png" />
          <!-- <p style="margin-top: 25px" class="left-area">
            29~30F, 17, Seobinggo-ro, Yongsan-gu, Seoul, Republic of Korea
          </p> -->
          <p class="left-area">
            contact@meta-creator.network
          </p>
        </div>

        <div class="footer-right footer-ele">
          <a href="mailto:contact@meta-creator.network">
          <div class="input-wrap">
            <p style="margin-right: 20px">Contact to us</p>
            <!-- <input placeholder="your email" id="footer-input" @keyup.enter="inputEnter" /> -->
            <div class="circle animate__animated animate__heartBeat animate__infinite">
              <font-awesome-icon icon="fa-solid fa-arrow-down"  class="arrow " />
            </div>
          </div>
          </a>
        </div>
      </div>

      <div class="logo_condition"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import $ from "jquery";
import axios from 'axios';

export default {
  name: 'JoinView',
  methods: {
    inputEnter() {
      var inputValue = $('#footer-input').val();
      $("#footer-input").val("");
      
      if(inputValue == "") {
        return ;
      }

      const api = `/api/email/${inputValue}`;
      // https://meta-creator.network/api/email/:email:
      axios.get(api).then((res)=> {
        console.log(res);
        alert('subscribed');
      })
      .catch((err) => {
        alert(err);
      })
    }
  },
  mounted() {

    // footer-left
    
    let bodyWidth = $('body').width();

    if(bodyWidth <= 1400) {
      $(".left-area").css({ marginLeft: 0});
      
      $('.footer .footer-right').css({ marginRight: '10px' });
      $('.footer .footer-left').css({ marginLeft: '10px' });
      $('.footer .footer-left').css({ width: '500px' });
    }


    $('.arrow').click(function() {
      this.inputEnter();
    })
    let logo_condition = document.querySelector('.logo_condition');
    
    let observer = new IntersectionObserver((e) => {
      e.forEach((background) => {
        //  && (background.intersectionRatio >= 0.275)
        if(background.isIntersecting) {
          $("#logo3").css({"display": "none"});
          $("#logo4").css({"display": "block"});
        }else{
          $("#logo4").css({"display": "none"});
          $("#logo3").css({"display": "block"});
        }
      })
    })

    observer.observe(logo_condition);

    let observer2 = new IntersectionObserver((e) => {
      e.forEach((element, index) => {
        if (element.isIntersecting) {
          setTimeout(() => {
            element.target.classList.add('transition-shakes');
          }, 50 * index);
        }else{
          element.target.classList.remove('transition-shakes');
        }
      });
    });
 
    let footerEle = document.querySelectorAll('.footer-ele');
    footerEle.forEach(ele => observer2.observe(ele))

  }
}
</script>

<style scoped>
.transition-shakes {
  animation-iteration-count: 0;
  animation: shakes 1.5s 1 ease;
}

@keyframes shakes {
  0% {
    transform: translateX(250px);
  }

  30% {
    transform: translateX(-20px);
  }

  60% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0px);
  }
}

.logo_condition {
  width: 100%;
  height: 5vh;
  background: none;
  position: absolute;
  bottom: 0px;
}
.footer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;

  /* background: url('../assets/images/footer-background.png');
  background-size: 1920px 100%; */
  /* background: rgb(169, 29, 212);
  background: linear-gradient(39deg, rgba(169, 29, 212, 1) 0%, rgba(40, 21, 46, 1) 90%, rgba(37, 21, 42, 1) 92%, rgba(26, 20, 28, 1) 100%); */
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .wrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer .footer-left {
  color: #fff;

  /* width: 1000px; */

  font-size: 28px;
  font-weight: 600;
  margin-left: 100px;
}

.footer-left p:first-child {
  margin-top: 25px;
}

.footer .footer-right {
  color: #fff;
  font-size: 48px;
  font-weight: 900;

  display: flex;
  flex-direction: column;

  margin-top: 30px;
  margin-right:200px;
}
.footer .footer-right .input-wrap{ 
  margin-top: -30px;
}

.input-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer .footer-right input {
  width: 400px;
  background: none;
  color: black;
  font-style: 'articulat-cf';
  font-weight: 900;
  font-size: 1rem;
  border-bottom: 2px solid black;
  margin-top: 40px;
}
input:focus { outline: none !important;  }

input::placeholder {
  color: black;
  font-style: 'articulat-cf';
  font-weight: 900;
  font-size: 28px;
}

.circle {
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid white;

  margin-left: 10px;
}
.arrow {
  position: absolute;
  top: 18px;
  left: 23px;

  transform: rotate(-90deg) !important;
  font-size: 40px;
}

.left-area {
  margin-top: 10px;
  margin-left: 164px;
  letter-spacing: -0.5px;
}

.background {
  position: absolute;
  background-color: #a91dd4;
  transform: rotate(6deg);
  top: 170px;
  left: -333px;
  z-index: -1;
  width: 200%;
  height: 120%;
}
</style>