<template>
  <div class="main-page">
    <div class="mcnt">
      <div class="content-area">
     
        <!-- <img src="../assets/images/mcnt_logo.gif" style="width:140px" /> -->
        <img src="../assets/images/mcnt-logo.png" class="transition-text transition-text-desc" />
        <div class="text-area transition-text transition-text-desc">
          <p class="mcnt-desc">
            Meta Creator Network + Token
          </p>
          <p class="mcnt-desc-sub">
            MNCT is the utility token of the MCN ecosystem.
          </p>
          <!-- <p class="mcnt-desc-sub">
            and can be used to make better creative assets.
          </p> -->
        </div>
      </div>
      <video src="../assets/video/etc_back.mp4" muted autoplay="true" loop="true" playsinline="true"></video>
      <div class="blur_test">
      </div>
      <div class="blur_test2">
      </div>
      <!-- <video src="../assets/video/temp3.mp4" muted autoplay="true" loop="true"></video> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  mounted() {
    let observer = new IntersectionObserver((e)=> {
      e.forEach(element => {
        if (element.isIntersecting) {
          element.target.style.opacity = 1;
          element.target.classList.add('animate__animated','animate__slideInUp');
        }else{
          element.target.style.opacity = 0;
          element.target.classList.remove('animate__animated','animate__slideInUp');
        }
      });
    });

    let div = document.querySelectorAll('.transition-text-desc');
    observer.observe(div[0]);
    observer.observe(div[1]);
  }
}
</script>

<style scoped>
.blur_test {
  width: 200%;
  height: 50px;
  position: absolute;
  top: -10px;
  left: -300px;

  background: #1a141c;
  filter: blur(10px);
  z-index:1;
}

.blur_test2 {
  width: 200%;
  height: 50px;
  position: absolute;
  left: -300px;
  bottom: -27px;

  background: #1a141c;
  filter: blur(10px);
  z-index:1;
}
  .mcnt {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mcnt .content-area {
    width: 800px;
    z-index: 4444;
  }

  .mcnt video {
    border-radius: 8px;
    position: absolute;
    object-fit: fill;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 1;
  }

  .mcnt .text-area  {
    margin-left: 180px;
  }

  .mcnt .mcnt-desc {
    font-size: 30px;
    color: white;
    font-weight: 600;
  } 
  .mcnt .mcnt-desc-sub {
    font-size: 24px;
    color: white;
    font-weight: 100;
  } 
</style>