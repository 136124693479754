var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-pages",staticStyle:{"overflow":"hidden"},attrs:{"id":"section2"}},[_c('div',{staticClass:"blur_div"}),_c('div',{staticClass:"blur_div2"}),_vm._m(0),_c('div',{staticClass:"app"},[_c('div',{staticClass:"infoList"},[_c('div',{staticClass:"info__wrapper"},[_c('div',{staticClass:"info current--info"},[_c('div',{staticClass:"slide-title developer"},[_vm._v(" ADVERTISER "),_c('font-awesome-icon',{staticClass:"minus",attrs:{"icon":"fa-solid fa-circle-minus"}})],1),_vm._m(1)]),_c('div',{staticClass:"info next--info"},[_c('div',{staticClass:"slide-title creator"},[_vm._v(" CREATOR "),_c('font-awesome-icon',{staticClass:"minus",attrs:{"icon":"fa-solid fa-circle-minus"}})],1),_vm._m(2)]),_c('div',{staticClass:"info previous--info"},[_c('div',{staticClass:"slide-title"},[_vm._v(" SUPERFAN "),_c('font-awesome-icon',{staticClass:"minus",attrs:{"icon":"fa-solid fa-circle-minus"}})],1),_vm._m(3)])])]),_c('div',{staticClass:"cardList"},[_c('div',{staticClass:"cards__wrapper"},[_c('div',{staticClass:"card current--card"},[_c('div',{staticClass:"card__image"},[_c('video',{attrs:{"src":require("../../assets/video/m-developer.mp4"),"muted":"true","autoplay":"true","loop":"ture","playsinline":"true"},domProps:{"muted":true}})])]),_c('div',{staticClass:"card next--card"},[_c('div',{staticClass:"card__image"},[_c('video',{attrs:{"src":require("../../assets/video/m-creator.mp4"),"muted":"true","loop":"ture","playsinline":"true"},domProps:{"muted":true}})])]),_c('div',{staticClass:"card previous--card"},[_c('div',{staticClass:"card__image"},[_c('video',{attrs:{"src":require("../../assets/video/m-community.mp4"),"muted":"true","loop":"ture","playsinline":"true"},domProps:{"muted":true}})])])])]),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"what-mcn",staticStyle:{"color":"#fff"}},[_c('div',{staticClass:"gradient-background"}),_c('div',{staticClass:"text-area"},[_c('div',{staticClass:"what-title"},[_c('p',{staticClass:"transition-text"},[_vm._v("WHAT")]),_c('p',{staticClass:"transition-text"},[_vm._v("IS "),_c('span',{staticClass:"bora"},[_vm._v("MCN?")])])]),_c('div',{staticClass:"desc",staticStyle:{"margin-top":"-4px"}},[_c('p',[_vm._v("Meta Creator Network is web3 friendly, platform agnostic creator membership to connect creators and superfans directly with advertisers who want to reach them.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('p',[_c('strong',[_vm._v("ADVERTISER")])]),_c('p',[_vm._v("upload ad missions on the mission board to advertise")]),_c('p',[_vm._v("their dApp and content. Setting various goals and rewards,")]),_c('p',[_vm._v("developers acquire activate users from missions.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('p',[_c('strong',[_vm._v("Creators")])]),_c('p',[_vm._v("are the main influencer of the creator community.")]),_c('p',[_vm._v("They select and operate ad missions posted by developers")]),_c('p',[_vm._v("to achieve rewards within their community.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description"},[_c('p',[_c('strong',[_vm._v("SUPERFAN")])]),_c('p',[_vm._v("represents the identity of the creator")]),_c('p',[_vm._v("in themes of games, art, food and etc.")]),_c('p',[_vm._v("Community members complete mission tasks")]),_c('p',[_vm._v("from the creator")]),_c('p',[_vm._v("and gets reward distributions as compensation.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app__bg"},[_c('div',{staticClass:"app__bg__image current--image",attrs:{"id":""}},[_c('img',{attrs:{"src":require("../../assets/images/m-developer.jpeg"),"alt":""}})]),_c('div',{staticClass:"app__bg__image next--image"},[_c('img',{attrs:{"src":require("../../assets/images/m-creator.jpeg"),"alt":""}})]),_c('div',{staticClass:"app__bg__image previous--image"},[_c('img',{attrs:{"src":require("../../assets/images/m-communities.jpeg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-button-wrap"},[_c('div',{staticClass:"image-button developer"},[_c('p',[_vm._v("ADVERTISER")]),_c('img',{attrs:{"src":require("../../assets/images/m-developer.jpeg"),"alt":""}})]),_c('div',{staticClass:"image-button creator"},[_c('p',[_vm._v("CREATOR")]),_c('img',{attrs:{"src":require("../../assets/images/m-creator.jpeg"),"alt":""}})]),_c('div',{staticClass:"image-button communities"},[_c('p',[_vm._v("SUPERFAN")]),_c('img',{attrs:{"src":require("../../assets/images/m-communities.jpeg"),"alt":""}})])])
}]

export { render, staticRenderFns }