<template>
  <div class="what-mcn">
    <div class="gradient-background">

    </div>

    <div class="text-area">
      <div class="what-title">
        <p class="transition-text">WHAT</p>
        <p class="transition-text">IS <span class="bora">MCN?</span></p>
      </div>
      <div class="desc" style="margin-left: 80px">
        <p> Meta Creator Network is web3 friendly, platform agnostic creator membership to connect creators and superfans directly with advertisers who want to reach them.</p>
        <p> </p>
        <p>  </p>
        <!-- <p>MCN, a Web3 AD network by the creator community,</p>
        <p>connects creators, communities and developers</p>
        <p>to expand the boundaries of the <Strong>creator economy.</Strong></p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let observer = new IntersectionObserver((e)=> {
      e.forEach(element => {
        if (element.isIntersecting) {
          element.target.style.opacity = 1;
          element.target.classList.add('animate__animated','animate__slideInUp');
        }else{
          element.target.style.opacity = 0;
          element.target.classList.remove('animate__animated','animate__slideInUp');
        }
      });
    });

    let div = document.querySelectorAll('.transition-text');
    observer.observe(div[0]);
    observer.observe(div[1]);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.what-mcn {
  position: relative;
}
.what-mcn .gradient-background {
  position: absolute;  
  top: 76px;
  left: 26px;

  z-index: 1;
  //716 564
  width: 760px;
  height: 520px;
  border-radius: 40px;

  background: rgb(26, 20, 28);
  background: linear-gradient(230deg, rgba(26, 20, 28, 1) 40%, rgba(38, 19, 44, 1) 50%, rgba(85, 15, 106, 1) 94%, rgba(85, 15, 106, 1) 100%);

  transform: rotate(6deg);
}

.what-mcn .text-area {
  width: 600px;
  position: absolute;
  color: #fff;
  
  z-index: 2000;
}
.what-mcn .text-area .what-title{
  padding:0px;
  margin: 0px;
  font-size: 9rem;
  font-weight: 900;
  -webkit-text-stroke: 3px white;
}

.what-mcn .text-area .what-title p:first-child{
  margin-top: 104px;
}
.what-mcn .text-area .what-title p:nth-of-type(2){
  margin-top: -84px;
  margin-right: -68px;
  text-align: right;
}
.bora {
  color: #a91dd4;
  -webkit-text-stroke: 3px #a91dd4;
  margin-right:10px;
}

</style>