<template>
  <div class="home">
    <CasperPointer />
    <MainView />
    <MCNView />
    <DescSliderView />
    <MCNTView />
    <PartnersView />
    <FooterView />
  </div>
</template>

<script>
import CasperPointer from "../components/CasperPointer.vue";
import MainView from "./MainView.vue";
import MCNView from "./MCNView.vue";
import PartnersView from "./PartnersView.vue";
import FooterView from "./FooterView.vue";
import MCNTView from "./MCNTView.vue";
import DescSliderView from "./DescSliderView.vue";

// import $ from 'jquery';

export default {
  name: 'HomeView',
  components: {
    MainView,
    MCNView,
    MCNTView,
    DescSliderView,
    FooterView,
    PartnersView,
    CasperPointer
  },
}
</script>
