<template>
  <div class="main-page" style="position: relative;">
    <div class="desc-slider-wrap">
      <div class="desc-text-wrap">
        <p class="transition-text2 transition-text desc-title">
          Main Features
        </p>
        <div class="wave" @click="nextChange">
          <p class="animate__animated animate__wobble animate__infinite">
            <font-awesome-icon icon="fa-solid fa-arrow-down"  class="arrow " />
          </p>
        </div>
        <div class="gradient-background">
        </div>
      </div>
      <div class="desc-slider">
        <Splide :options="options" @splide:active="activeChange" ref="splide">
          <SplideSlide>
          </SplideSlide>

          <SplideSlide>
            <div class="slide-unit" @click="clickChange" data-slide-id="1">
              <p class="unit-title">Creator </p>
              <p class="unit-title">Membership</p>

              <div class="sub-desc-wrap" data-slide-id="1">
                <p class="unit-sub-desc">Join the creator membership</p>
                <p class="unit-sub-desc">and stay updated on new missions</p>
                <p class="unit-sub-desc">and community event.</p>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div class="slide-unit" @click="clickChange"  data-slide-id="2">
              <p class="unit-title">MCN Wallet</p>
              <p class="unit-title">System</p>

              <div class="sub-desc-wrap" data-slide-id="2" style="top: 126px">
                <p class="unit-sub-desc">CREA is an MCN wallet system for creators</p>
                <p class="unit-sub-desc">and community members to manage crypto assets. Enjoy easy</p>
                <p class="unit-sub-desc">transfer between community members.</p>
                <!-- <p class="unit-sub-desc"></p> -->
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div class="slide-unit" @click="clickChange"  data-slide-id="3">
              <p class="unit-title">Mission</p>
              <p class="unit-title">& Reward</p>
             
              <div class="sub-desc-wrap" data-slide-id="3">
                <p class="unit-sub-desc">Mission board is the playground for creators,</p>
                <p class="unit-sub-desc">superfans, and advertisers. Explore and complete missions together with your favorite</p>
                <p class="unit-sub-desc">creator.</p>
              </div>
            </div>
          </SplideSlide>
          
          <SplideSlide>
            <!-- <div class="slide-unit" @click="clickChange" data-slide-id="4">
              <p class="unit-title">Heimdall</p>
              <p class="unit-title">SDK</p>
             
              <div class="sub-desc-wrap" data-slide-id="4">
                <p class="unit-sub-desc">Heimdall SDK tracks community member's</p>
                <p class="unit-sub-desc">data on play history and mission progress</p>
                <p class="unit-sub-desc">to verify mission status.</p>
              </div>
            </div> -->
          </SplideSlide>
          
          <SplideSlide>
          </SplideSlide>
        </Splide>
      </div>
    </div>
    <div class="blur_test">
      
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import $ from 'jquery';

export default {
  components: { Splide, SplideSlide },
  data() {
    return {
      slider: null,
      options: {
        type: 'slide',
        perPage: 3,
        focus: 'center',
        height: '400px',
        width: '1920px',
        fixedWidth: '400px',
        gap: '20px',
        arrows: false,
        pagination: false,
      },
    }
  },
  methods: {
    nextChange() {
      if(this.slider.index == '3') {
        this.slider.go(1);
      }else{
        let temp = this.slider.index;
        temp += 1;
        this.slider.go(temp);
      }
      
      // this.slider.next();
    },
    clickChange(e) {
      let id="";
      if(e.target.nodeName === "P") {
        id=e.target.parentNode.dataset.slideId;
      }else{
        id=e.target.dataset.slideId;
      }

      this.slider.go(id);

    },
    activeChange(e) {
        $('.slide-unit').css({
          'border': '1px solid #a91dd4',
          'background': '#1a141c'
        });

        $('.slide-unit p').css({
          'color': '#a91dd4',
        });

        $('.splide__slide').eq(e.index).find('.slide-unit').css({
          'border': '1px solid #fff',
          'background': 'linear-gradient(45deg, rgba(169, 29, 212, 0.53) 13%, rgba(85, 15, 106, 0) 100%)'
        });
        $('.splide__slide').eq(e.index).find('.slide-unit p').css({
          'color': '#fff'
        });
    }
  },
  mounted() {

    let bodyWidth = $('body').width();

    if(bodyWidth <= 1120) {
      $(".desc-title").css({ fontSize: '6rem'});
      $('.splide').css({ width: '1500px' });
    }
    
    this.slider = this.$refs.splide;
    this.slider.go(1);
 
    let observer = new IntersectionObserver((e)=> {
      e.forEach(element => {
        if (element.isIntersecting) {
          element.target.style.opacity = 1;
          element.target.classList.add('animate__animated','animate__slideInUp');
        }else{
          element.target.style.opacity = 0;
          element.target.classList.remove('animate__animated','animate__slideInUp');
        }
      });
    });

    let div = document.querySelectorAll('.transition-text2');
    observer.observe(div[0]);
  }
}
</script>

<style scoped lang="scss">
.splide__arrows {
  display: none !important;
}

.splide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 470px !important;
}

.is-active .desc-slider .slide-unit{
  border: 1px solid whtie !important;
}

.sub-desc-wrap {
  position: absolute;
  top: 140px;
  left: 48px;
}

.unit-title {
  color: #a91dd4;
  font-size: 30px;
  font-weight: 900;

  transform: rotate(-6deg);
}

.slide-unit p.unit-title:nth-of-type(2) {
  margin-left: 6px;
  margin-top: -10px;
}

.slide-unit p.unit-sub-desc:nth-of-type(2) {
  margin-left: 5px;
}

.slide-unit p.unit-sub-desc:nth-of-type(3) {
  margin-left: 7px;
}

.slide-unit p.unit-sub-desc:nth-of-type(4) {  
  margin-left: 9px;
}

.unit-sub-desc {
  color: #a91dd4;
  font-size: 20px;
  font-weight: 300;

  transform: rotate(-6deg);
}

.desc-slider {
  position: absolute;
  top: 31vh;
  left: 0vw;

}
.desc-slider .slide-unit {
  cursor:pointer;
  border: 1px solid #a91dd4;
  width: 470px;
  height: 300px;
  color: #a91dd4;
  transform: rotate(6deg);
  padding-top: 40px;
  padding-left: 40px;

  position: relative;
  border-radius: 20px;
  background: #1a141c;
}
.desc-slider-wrap {
  width: 100%;
  height: 700px;

  z-index: 4444;
  position: relative;
  padding-left: 15vw;
  padding-top: 8vh;
}

.desc-text-wrap {
  position: relative;
}

.desc-title {
  position: absolute;
  top: 40px;
  color: #fff;
  z-index: 4444;
  font-size: 9rem;
  font-weight: 900;
  -webkit-text-stroke: 3px white;
}

.gradient-background {
  position: absolute;
  width: 760px;
  height: 500px;
  left: -20px;
  border-radius: 40px;
  background-image: linear-gradient(63deg, rgba(171, 40, 212, 0) 32%, #550f6a 112%);

  transform: rotate(6deg);
}

.arrow {
  position: absolute;
  font-size: 50px;
  color: #a91dd4;
  transform: rotate(270deg);
}

.wave {
  cursor: pointer;
  width: 100px;
  height: 100px;
  background: none;
  border-radius: 50%;

  position: absolute;
  right: 0px;
  top: 90px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: #a91dd4; //#a91dd4
    top: -24px;
    left: -28px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: wave 3s infinite linear;
  }

  &:after {
    opacity: 0;
    animation: wave 3s 1.5s infinite linear;
  }
}

@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0.7;
  }

  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}
</style>